import React from 'react'
import styled from 'styled-components'
import { FaEnvelope, FaPhone, FaMapMarker } from 'react-icons/fa'

import { Jumbo } from '../components/jumbotron'
import Layout  from '../components/layout'
import SEO from "../components/seo"


const Contact = () => (
  <Layout>
    <SEO title="Kontakt" />
    <Styles>
      <Jumbo title="Kontakt"></Jumbo>
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-5">
            <iframe title="Google Map" width="100%" height="400" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=Rastislavova%20786%2C%20Kosice+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=18&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
              <a href="https://www.maps.ie/draw-radius-circle-map/">Google Maps radius calculator</a>
            </iframe>
          </div>
          <div className="col-md-3 mb-3">
            <h5>Adresa</h5>
            <p>OCEAN DENT s.r.o.</p><br/>
            <p><FaMapMarker/> Rastislavova 786/45</p>
            <p><FaMapMarker style={{visibility: "hidden"}}/> 040 01 Košice</p>
            <br/>
            <p>Nachádzame sa v: Poliklinika JUH</p>
            <p>3. poschodie - vpravo od výťahov</p>
          </div>
          <div className="col-md-4 mb-3">
            <h5>Telefón</h5>
            <p><FaPhone/> +421 910 399 793</p>
            <br/>
            <h5>E-mail</h5>
            <p><FaEnvelope/> oceandentkosice@gmail.com</p>
          </div>
        </div>

      </div>
    </Styles>
  </Layout>
)

const Styles = styled.div`
  p {
    margin-bottom: 2%;
    color: dimgrey;
  }
  h5 {
    color: #5dc2de;
  }
`;

export default Contact